import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["question", "answer", "icon"]

  connect() {
    this.activeIndex = null
  }

  toggle(event) {
    const index = parseInt(event.currentTarget.dataset.index)
    
    if (this.activeIndex === index) {
      this.close(index)
    } else {
      if (this.activeIndex !== null) {
        this.close(this.activeIndex)
      }
      this.open(index)
    }
  }

  open(index) {
    this.answerTargets[index].classList.remove("hidden")
    this.iconTargets[index].classList.add("rotate-180")
    this.activeIndex = index
  }

  close(index) {
    this.answerTargets[index].classList.add("hidden")
    this.iconTargets[index].classList.remove("rotate-180")
    this.activeIndex = null
  }
}