import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flashes"
export default class extends Controller {
  static targets = ['flashToaster'];

  connect() {
    // Automatically remove the flash after 5 seconds
    setTimeout(() => {
      this.fadeAndRemove();
    }, 3000); // 3 seconds
  }

  fadeAndRemove() {
    this.flashToasterTarget.classList.add('fade-out');
    // Wait for the animation to complete before removing the element
    setTimeout(() => {
      this.flashToasterTarget.remove();
    }, 1000); // Corresponds to the animation duration
  }

  remove() {
    this.fadeAndRemove();
  }
}