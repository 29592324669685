import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-generation"
export default class extends Controller {
  static targets = ["aspectRatio"]

  connect() {
  }

  updateDimensions(event) {
    const buttons = document.querySelectorAll('.aspect-ratio-button')
    
    buttons.forEach(button => {
      button.classList.remove('selected')
    })
    
    event.currentTarget.classList.add('selected')
    
    // Add any additional logic you need for updating dimensions
  }
}