import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["basePhoto", "targetMedia", "placeholder"]

  loadBasePhoto(event) {
    this.loadImage(event, this.basePhotoTarget, this.placeholderTarget);
  }

  loadTargetMedia(event) {
    this.loadImage(event, this.targetMediaTarget, this.placeholderTarget);
  }

  loadImage(event, element, placeholder) {
    const files = event.target.files;
    if (files.length === 0) {
      // If no new file is selected, clear the background image
      element.style.backgroundImage = 'none';
      placeholder.style.display = 'flex';
      element.classList.remove('hover:brightness-50');
      return;
    }

    const file = files[0]; // Get the first file
    const reader = new FileReader();
    reader.onload = (event) => {
      // Update the background of the element with the new image
      element.style.backgroundImage = `url('${event.target.result}')`;
      element.style.backgroundSize = 'cover';
      element.style.backgroundPosition = 'center';
      // Apply a brightness effect on hover
      element.classList.add('hover:brightness-50');
      // Hide the placeholder once an image is loaded
      placeholder.style.display = 'none';
    };
    reader.readAsDataURL(file); // Start reading the file as Data URL
  }
}