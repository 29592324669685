import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="credit-package"
export default class extends Controller {
  static targets = ["button", "creditAmount", "price", "hiddenAmount"]

  connect() {
    this.selectPackage({ target: this.buttonTargets[2] }) // Select $100 by default
  }

  selectPackage(event) {
    const amount = parseInt(event.target.dataset.amount)
    const credits = parseInt(event.target.dataset.credits)

    this.buttonTargets.forEach(button => {
      button.classList.remove("bg-teal-600", "text-white")
      button.classList.add("bg-gray-700", "text-gray-300")
    })

    event.target.classList.remove("bg-gray-700", "text-gray-300")
    event.target.classList.add("bg-teal-600", "text-white")

    this.creditAmountTargets.forEach(el => el.textContent = credits)
    this.priceTarget.textContent = amount
    this.hiddenAmountTarget.value = credits
  }
}
