import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pricing"
export default class extends Controller {
  static targets = ["monthlyItem", "yearlyItem"];

  update(event) {
    const interval = event.target.value;

    this.monthlyItemTargets.forEach((element) => {
      element.classList.toggle("hidden", interval !== "monthly");
    });

    this.yearlyItemTargets.forEach((element) => {
      element.classList.toggle("hidden", interval !== "yearly");
    });
  }
}
